import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import UserDetailTable from "./Table/UserDetailTable";
import { updateUser } from "../data/user";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1400,
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const grid = {
  height: "100%",
};

const gridCell = {
  height: "30%",
};

const UserModal = (props) => {
  const { open, handleClose, user, updateUsers } = props;

  const [kyc, setKyc] = useState(user.kyc);
  const [blocked, setBlocked] = useState(user.blocked);

  useEffect(() => {
    setKyc(props.user.kyc);
    setBlocked(props.user.blocked);
  }, [props.user]);

  const columnsCoins = [
    {
      width: 120,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "価格",
      dataKey: "jpy",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsBuyPosts = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsBuyItems = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsSalesPosts = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 60,
      label: "レート",
      dataKey: "rate",
      numeric: true,
    },
    {
      width: 60,
      label: "ポイント",
      dataKey: "point",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsSalesItems = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 60,
      label: "レート",
      dataKey: "rate",
      numeric: true,
    },
    {
      width: 60,
      label: "ポイント",
      dataKey: "point",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const handleOnSubmitKyc = async (e) => {
    e.preventDefault();

    const data = { kyc: kyc, blocked: blocked };

    // console.log("handleOnSubmitKyc", data);
    const ret = await updateUser(user.uid, data);
    if (ret) {
      user.kyc = kyc.toString(10);
      user.blocked = blocked.toString(10);
      updateUsers(user);
      toast.success("更新しました");
    } else {
      toast.error("更新に失敗しました");
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="mb-4 text-right">
          <button
            onClick={handleClose}
            className="w-6 h-6 inline-flex items-center justify-center bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white border border-gray-500 hover:border-transparent rounde"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} sx={grid}>
          <Grid item xs={6} sx={gridCell}>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full">
              <form className="h-full flex flex-col justify-between" onSubmit={(e) => handleOnSubmitKyc(e)}>
                <div className="flex space-x-4">
                  <div>
                    <InputLabel id="selectKyc">本人認証</InputLabel>
                    <Select value={kyc} onChange={(e) => setKyc(e.target.value)}>
                      <MenuItem value={0}>未確認</MenuItem>
                      <MenuItem value={1}>確認済</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <InputLabel id="selectBlocked">状態</InputLabel>
                    <Select value={blocked} onChange={(e) => setBlocked(e.target.value)}>
                      <MenuItem value={0}>有効</MenuItem>
                      <MenuItem value={1}>停止中</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    適用
                  </button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={6} sx={gridCell}>
            <UserDetailTable data={user.coins ? user.coins : []} columns={columnsCoins} />
          </Grid>
          <Grid item xs={6} sx={gridCell}>
            <UserDetailTable data={user.buyPosts ? user.buyPosts : []} columns={columnsBuyPosts} />
          </Grid>
          <Grid item xs={6} sx={gridCell}>
            <UserDetailTable data={user.buyItems ? user.buyItems : []} columns={columnsBuyItems} />
          </Grid>
          <Grid item xs={6} sx={gridCell}>
            <UserDetailTable data={user.salesPosts ? user.salesPosts : []} columns={columnsSalesPosts} />
          </Grid>
          <Grid item xs={6} sx={gridCell}>
            <UserDetailTable data={user.salesItems ? user.salesItems : []} columns={columnsSalesItems} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserModal;
