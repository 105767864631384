import React from "react";
import Instance from "../util/AxiosInstance";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Login = () => {
  const { setIsAuth } = useAuth();

  const navigate = useNavigate();

  // バリデーションのフォームを定義
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // ログインフォームの送信ボタン押下時に実行
  const onSubmit = (data) => {
    // パラメータ設定
    var params = new URLSearchParams();
    params.append("username", data.username);
    params.append("password", data.password);

    // ログイン API へ POST
    Instance.post("/auth/", params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) => {
      console.log(response);
      if (response.data === "success") {
        setIsAuth(true);
        navigate("/");
      }
    });
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="bg-gray-100 flex-auto">
        <div className="flex justify-center mt-20">
          <div className="w-4/12 border bg-white">
            <div className="my-16 text-center">
              <h2 className="text-4xl font-bold">ログイン</h2>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-12 flex flex-col items-center">
                <div className="mb-3 flex flex-col items-start">
                  <p className="event-title">アカウント名</p>
                  <input type="text" {...register("username", { required: true })} className="text-xl w-full p-3 border rounded" />
                  <p>{errors.username?.type === "required" && "アカウント名を入力してください。"}</p>
                </div>
                <div className="mb-3 flex flex-col items-start">
                  <p className="event-title">パスワード</p>
                  <input type="password" {...register("password", { required: true })} className="text-xl w-full p-3 border rounded" />
                  <p>{errors.password?.type === "required" && "パスワードを入力してください。"}</p>
                </div>
                <input type="submit" value="ログイン" className="mb-3 text-xl bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
