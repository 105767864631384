import Instance from "../util/AxiosInstance";

export const getMultiSelect = async (mode) => {
    return await Instance.get("/multiselect/", { params: { mode: mode } })
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getMultiSelect', response);
                return response.data;
            } else {
                return [];
            }
        });
}

