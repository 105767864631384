import { HiViewGrid } from "react-icons/hi";

export const _DASHBORD_SIDEBAR_LINKS = [
  {
    key: "dashbord",
    label: "ホーム",
    path: "/",
    icon: <HiViewGrid />,
  },
  {
    key: "agency",
    label: "代理店管理",
    path: "/agency",
    icon: <HiViewGrid />,
  },
  {
    key: "user",
    label: "ユーザー管理",
    path: "/user",
    icon: <HiViewGrid />,
  },
  // {
  //   key: "infomation",
  //   label: "お知らせ管理",
  //   path: "/infomation",
  //   icon: <HiViewGrid />,
  // },
  {
    key: "appsetting",
    label: "アプリ設定",
    path: "/appsetting",
    icon: <HiViewGrid />,
  },
  // {
  //   key: "coin",
  //   label: "コイン設定",
  //   path: "/coin",
  //   icon: <HiViewGrid />,
  // },
  // {
  //   key: "point",
  //   label: "ポイント設定",
  //   path: "/point",
  //   icon: <HiViewGrid />,
  // },
  // {
  //   key: "lank",
  //   label: "ランク設定",
  //   path: "/lank",
  //   icon: <HiViewGrid />,
  // },
];
