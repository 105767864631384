import Instance from "../util/AxiosInstance";

export const getSystem = async () => {
    return await Instance.get("/system/")
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getAppVersion', response);
                return response.data;
            } else {
                return [];
            }
        });
}

export const updateSystem = async (val, type) => {
    return await Instance.post("/system/", { val: val, type: type })
        .then((response) => {
            if (response.data !== "failure") {
                console.log('updateSystem', response);
                return response.data;
            } else {
                return [];
            }
        });
}
