import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAgencyFromKeyword } from "../data/agency";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import AgencyModal from "../componets/AgencyModal";

const Agency = () => {
  const [agencies, setAgencies] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    let get = "";

    if (formData.keyword) {
      get = formData.keyword;
    }

    const ret = await getAgencyFromKeyword(get);
    console.log("onSubmit", ret);
    setAgencies(ret);
  };

  /* モーダル制御 */
  const [selectAgency, setSelectAgency] = useState({});
  const [open, setOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenModal = (agency, mode) => {
    console.log("handleOpenModal", agency, mode);
    setModalMode(mode);
    if (mode == "add") {
      setSelectAgency();
    } else {
      setSelectAgency(agency);
    }
    setOpen(true);
  };

  const updateAgencies = (currentAgency) => {
    if (!currentAgency) return;
    console.log("currentAgency", currentAgency);
    const newAgency = agencies.map((agency) => {
      if (agency.id === currentAgency.id) {
        return currentAgency;
      } else {
        return agency;
      }
    });
    console.log("newAgency", newAgency);
    setAgencies(newAgency);
  };

  const addAgencies = (currentAgency) => {
    if (!currentAgency) return;
    console.log("currentAgency", currentAgency);
    const newAgency = agencies.map((agency) => {
      if (agency.id === currentAgency.id) {
        return currentAgency;
      } else {
        return agency;
      }
    });
    console.log("newAgency", newAgency);
    setAgencies(newAgency);
  };

  useEffect(() => {
    const getAllAgency = async () => {
      const ret = await getAgencyFromKeyword("");
      setAgencies(ret);
      ret.length > 0 && setSelectAgency(ret[0]);
    };

    getAllAgency();
  }, []);

  return (
    <div className="flex flex-col">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} class="mt-6 flex items-center gap-3">
          <div className="mb-3 flex flex-col items-center">
            <input type="text" {...register("keyword", { required: false })} class="w-full p-3 border rounded" placeholder="キーワード" />
            <p>{errors.keyword?.type === "required" && "ユーザーキーを入力してください。"}</p>
          </div>
          <input type="submit" value="検索" class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
        </form>
        <form class="mt-6 flex items-center gap-3">
          <input type="button" value="新規代理店の追加" onClick={() => handleOpenModal({}, "add")} class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
        </form>
      </div>
      <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>会社名</TableCell>
                <TableCell>住所</TableCell>
                <TableCell>電話番号</TableCell>
                <TableCell>担当者名</TableCell>
                <TableCell>担当者電話番号</TableCell>
                <TableCell>担当者メールアドレス</TableCell>
                <TableCell>備考(備考は運営のみ表示)</TableCell>
                <TableCell>登録日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agencies.map((row) => (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="cursor-pointer" onClick={() => handleOpenModal(row, "edit")}>
                  <TableCell component="th" scope="row">
                    {row.agencyName}
                  </TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.tel}</TableCell>
                  <TableCell>{row.contactName}</TableCell>
                  <TableCell>{row.contactTel}</TableCell>
                  <TableCell>{row.contactMail}</TableCell>
                  <TableCell>{row.note}</TableCell>
                  <TableCell>{dayjs(row.created_at).format("YYYY年MM月DD日 HH:mm")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AgencyModal open={open} handleOpen={handleOpen} handleClose={handleClose} modalMode={modalMode} agency={selectAgency} updateAgencies={updateAgencies} addAgencies={addAgencies} />
    </div>
  );
};

export default Agency;
