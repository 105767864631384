import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { getUserFromKeyword } from "../data/user";
import UserModal from "../componets/UserModal";

const User = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    let get = "";

    if (formData?.keyword) {
      get = formData.keyword;
    }

    const ret = await getUserFromKeyword(get);
    // console.log("onSubmit", formData);
    setUsers(ret);
  };

  const handleReset = () => {
    onSubmit(null);
  };

  /* モーダル制御 */
  const [selectUser, setSelectUser] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenClient = (user) => {
    // console.log("handleOpenClient", user);
    setSelectUser(user);
    setOpen(true);
  };

  /* テーブル制御 */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const visibleRows = useMemo(() => users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [users, page, rowsPerPage]);

  useEffect(() => {
    const getAllUser = async () => {
      const ret = await getUserFromKeyword("");
      setUsers(ret);
      ret.length > 0 && setSelectUser(ret[0]);
    };

    getAllUser();
  }, []);

  const updateUsers = (currentUser) => {
    if (!currentUser) return;
    console.log("currentUser", currentUser);
    const newUsers = users.map((user) => {
      if (user.uid === currentUser.uid) {
        return currentUser;
      } else {
        return user;
      }
    });
    console.log("newUsers", newUsers);
    setUsers(newUsers);
  };

  return (
    <div className="flex flex-col">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} class="mt-6 flex items-center gap-3">
          <div className="mb-3 flex flex-col items-center">
            <input type="text" {...register("keyword", { required: false })} class="w-full p-3 border rounded" placeholder="ユーザーキー" />
            <p>{errors.keyword?.type === "required" && "ユーザーキーを入力してください。"}</p>
          </div>
          <input type="submit" value="検索" class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
          <input type="reset" value="リセット" onClick={handleReset} class="text-xs mb-3 bg-gray-400 text-gray-900 py-2 px-4 rounded hover:opacity-75" />
        </form>
      </div>
      <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>番組（チャンネル）名</TableCell>
                <TableCell>ニックネーム</TableCell>
                <TableCell>性別</TableCell>
                <TableCell>キー</TableCell>
                <TableCell>購入コイン</TableCell>
                <TableCell>放送購入</TableCell>
                <TableCell>ギフト購入</TableCell>
                <TableCell>保有コイン</TableCell>
                <TableCell>放送販売</TableCell>
                <TableCell>ギフト販売</TableCell>
                <TableCell>本人確認</TableCell>
                <TableCell>状態</TableCell>
                <TableCell>登録日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((row) => (
                <TableRow
                  key={row.uid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, "&:hover": { backgroundColor: "#ccc" } }}
                  className="cursor-pointer"
                  onClick={() => handleOpenClient(row)}
                >
                  <TableCell component="th" scope="row">
                    {row.channelName}
                  </TableCell>
                  <TableCell>{row.nickname}</TableCell>
                  <TableCell>{row.sex != "" ? (row.sex === "man" ? "男性" : row.sex === "weman" ? "女性" : "その他") : ""}</TableCell>
                  <TableCell>{row.userKey}</TableCell>
                  <TableCell align="right">{Number(row.coinTotal).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.postCoinTotal).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.itemCoinTotal).toLocaleString()}</TableCell>
                  <TableCell align="right">{row.coinTotal - row.postCoinTotal - row.itemCoinTotal}</TableCell>
                  <TableCell align="right">{Number(row.postPointTotal).toLocaleString()}</TableCell>
                  <TableCell align="right">{Number(row.itemPointTotal).toLocaleString()}</TableCell>
                  <TableCell align="center">
                    <div className={row.kyc === "1" && "bg-green-500 text-white rounded-sm"}>{row.kyc === "1" ? "確認済" : "未確認"}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={row.blocked === "1" && "bg-red-500 text-white rounded-sm"}>{row.blocked === "1" ? "停止中" : "有効"}</div>
                  </TableCell>
                  <TableCell>{dayjs(row.created_at).format("YYYY年MM月DD日 HH:mm")}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <UserModal open={open} handleOpen={handleOpen} handleClose={handleClose} user={selectUser} updateUsers={updateUsers} />
    </div>
  );
};

export default User;
