import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Layout from "./Layout";
import Home from "./Home";
import Login from "./Login";
import NotFound from "./NotFound";
import Agency from "./Agency";
import User from "./User";
import AppSetting from "./AppSetting";
import Infomation from "./Infomation";
import { RouteAuthGuard } from "../componets/RouteAuthGuard";

const RouterConfig = () => {
  const { isAuth } = useAuth();
  console.log("isAuth", isAuth);
  // 認証確認メソッド
  // → 認証されていない場合、ログインページにリダイレクト
  const RequireAuth = (props) => {
    // 権限が「GENERAL」の場合、渡されたコンポーネントをレンダリング
    if (isAuth) {
      return props.component;
    }

    // 権限がない場合、ログインページへリダイレクト
    document.location = "./login";
  };

  // 非認証確認メソッド
  const RequireNoAuth = (props) => {
    if (!isAuth) {
      return props.component;
    }

    // 権限が存在する場合、メディア一覧ページへリダイレクト
    document.location = "/";
  };

  return (
    <>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/login" element={<RequireNoAuth component={<Login />} />} />
            <Route path="/" element={<RouteAuthGuard component={<Layout />} redirect="/login" />}>
              <Route index element={<Home />} />
              <Route path="/agency" element={<Agency />} />
              <Route path="/user" element={<User />} />
              <Route path="/appsetting" element={<AppSetting />} />
              <Route path="/infomation" element={<Infomation />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};

export default RouterConfig;
