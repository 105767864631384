import Instance from "../util/AxiosInstance";

export const getUserFromKeyword = async (keyword) => {
    console.log(keyword);
    return await Instance.get("/users/?kw=" + keyword)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getUserFromKeyword', response);
                return response.data;
            } else {
                return [];
            }
        });
}

export const updateUser = async (id, formData) => {

    const json = JSON.stringify(formData);
    console.log('updateUser', json);

    return await Instance.put("/users/?id=" + id, json)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('updateUser', response);
                return response.data;
            } else {
                return [];
            }
        });
}