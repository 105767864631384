import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getInfomationFromKeyword } from "../data/infomation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import InfomationModal from "../componets/InfomationModal";

const Infomation = () => {
  const [infomations, setInfomations] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    let get = "";

    if (formData.keyword) {
      get = formData.keyword;
    }

    const ret = await getInfomationFromKeyword(get);
    console.log("onSubmit", ret);
    setInfomations(ret);
  };

  /* モーダル制御 */
  const [selectInfomation, setSelectInfomation] = useState({});
  const [open, setOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenModal = (infomation, mode) => {
    console.log("handleOpenModal", infomation, mode);
    setModalMode(mode);
    if (mode == "add") {
      setSelectInfomation();
    } else {
      setSelectInfomation(infomation);
    }
    setOpen(true);
  };

  const updateInfomations = (currentInfomation) => {
    if (!currentInfomation) return;
    console.log("currentInfomation", currentInfomation);
    const newInfomation = infomations.map((infomation) => {
      if (infomation.id === currentInfomation.id) {
        return currentInfomation;
      } else {
        return infomation;
      }
    });
    console.log("newInfomation", newInfomation);
    setInfomations(newInfomation);
  };

  const addInfomations = (currentInfomation) => {
    if (!currentInfomation) return;
    console.log("currentInfomation", currentInfomation);
    const newInfomation = infomations.map((infomation) => {
      if (infomation.id === currentInfomation.id) {
        return currentInfomation;
      } else {
        return infomation;
      }
    });
    console.log("newInfomation", newInfomation);
    setInfomations(newInfomation);
  };

  useEffect(() => {
    const getAllInfomation = async () => {
      const ret = await getInfomationFromKeyword("");
      setInfomations(ret);
      ret.length > 0 && setSelectInfomation(ret[0]);
    };

    getAllInfomation();
  }, []);

  return (
    <div className="flex flex-col">
      <div>
        {/* <form onSubmit={handleSubmit(onSubmit)} class="mt-6 flex items-center gap-3">
          <div className="mb-3 flex flex-col items-center">
            <input type="text" {...register("keyword", { required: false })} class="w-full p-3 border rounded" placeholder="キーワード" />
            <p>{errors.keyword?.type === "required" && "ユーザーキーを入力してください。"}</p>
          </div>
          <input type="submit" value="検索" class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
        </form> */}
        <form class="mt-6 flex items-center gap-3">
          <input type="button" value="新規お知らせの追加" onClick={() => handleOpenModal({}, "add")} class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
        </form>
      </div>
      <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>タイトル</TableCell>
                <TableCell>内容</TableCell>
                <TableCell>ボタンタイトル</TableCell>
                <TableCell>プレゼントコイン</TableCell>
                <TableCell>コイン履歴タイトル</TableCell>
                <TableCell>登録日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infomations.map((row, index) => (
                <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="cursor-pointer" onClick={() => handleOpenModal(row, "edit")}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{row.categoryTitle}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {row.image && <img src={row.image} className="w-20 object-contain" />}
                    {row.noteBack}
                  </TableCell>
                  <TableCell>{row.buttonName}</TableCell>
                  <TableCell>{row.serviceCoin}</TableCell>
                  <TableCell>{row.coinName}</TableCell>
                  <TableCell>{dayjs(row.created_at).format("YYYY年MM月DD日 HH:mm")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <InfomationModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        modalMode={modalMode}
        infomation={selectInfomation}
        updateInfomations={updateInfomations}
        addInfomations={addInfomations}
      />
    </div>
  );
};

export default Infomation;
