import Instance from "../util/AxiosInstance";

export const getStatistics = async () => {
    return await Instance.get("/statistics/")
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getStatistics', response);
                return response.data;
            } else {
                return [];
            }
        });
}
