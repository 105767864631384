import React from "react";

const NotFound = () => {
  return (
    <>
      <h1>404</h1>
      <h3>お探しのページは見つかりませんでした。</h3>
    </>
  );
};

export default NotFound;
