import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";

const UserDetailTable = (props) => {
  const { data, columns } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} sx={{ height: "100%" }} />),
    Table: (props) => <Table size="small" {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            // align={column.numeric || false ? "right" : "left"}
            align={"left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <Fragment>
        {columns.map((column) => (
          <TableCell key={column.dataKey} align={column.numeric || false ? "right" : "left"}>
            {column.date ? dayjs(row[column.dataKey]).format("YYYY年MM月DD日 HH:mm") : column.numeric ? Number(row[column.dataKey]).toLocaleString() : row[column.dataKey]}
          </TableCell>
        ))}
      </Fragment>
    );
  }

  useEffect(() => {
    // console.log("UserDetailTable", props);
  }, []);

  return (
    <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full">
      <TableVirtuoso data={data} components={VirtuosoTableComponents} fixedHeaderContent={fixedHeaderContent} itemContent={rowContent} />
    </div>
  );
};

export default UserDetailTable;
