import React from "react";
import { useAuth } from "../context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

export const RouteAuthGuard = (props) => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate to={props.redirect} replace={false} />;
  }

  return <>{props.component}</>;
};
