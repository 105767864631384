import Instance from "../util/AxiosInstance";

export const getAgencyFromKeyword = async (keyword) => {
    return await Instance.get("/agency/?kw=" + keyword)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getAgencyFromKeyword', response);
                return response.data;
            } else {
                return [];
            }
        });
}

export const updateAgency = async (id, formData) => {

    const json = JSON.stringify(formData);
    console.log('updateAgency', json);

    return await Instance.put("/agency/?id=" + id, json)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('updateAgency', response);
                return response.data;
            } else {
                return [];
            }
        });
}


export const addAgency = async (formData) => {

    const json = JSON.stringify(formData);
    console.log('addAgency', json);

    return await Instance.post("/agency/", json)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('addAgency', response);
                return response.data;
            } else {
                return [];
            }
        });
}