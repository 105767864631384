import React from "react";
import { _DASHBORD_SIDEBAR_LINKS } from "../../data/navigation";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { HiOutlineLogout } from "react-icons/hi";

const linkClasses = "flex item-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base";

const Sidebar = () => {
  return (
    <div className="bg-gray-900 w-60 p-3 text-white flex flex-col overflow-auto">
      <div className="text-xl">ミミコミ</div>
      <div className="flex-1 py-8 flex flex-col gap-0.5">
        {_DASHBORD_SIDEBAR_LINKS.map((item) => (
          <SidebarLink key={item.key} item={item} />
        ))}
      </div>
      <Link className={linkClasses}>
        <HiOutlineLogout /> ログアウト
      </Link>
    </div>
  );
};

const SidebarLink = ({ item }) => {
  const { pathname } = useLocation();

  return (
    <Link to={item.path} className={classNames(pathname === item.path ? "bg-gray-700 text-white" : "text-neutral-400", linkClasses)}>
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  );
};

export default Sidebar;
