import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Grid, Input, InputLabel, MenuItem, Select, TextareaAutosize } from "@mui/material";
import UserDetailTable from "./Table/UserDetailTable";
import { addInfomation, updateInfomation } from "../data/infomation";
import { toast } from "react-toastify";
import { Textarea } from "@material-tailwind/react";
import { type } from "@testing-library/user-event/dist/type";
import { getMultiSelect } from "../data/multiSelect";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1400,
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pb: 8,
};

const grid = {
  height: "100%",
};

const gridCell = {
  height: "100%",
};

const InfomationModal = (props) => {
  const { open, handleClose, modalMode, infomation, updateInfomations, addInfomations } = props;

  const [id, setId] = useState(infomation?.id ? infomation.id : "");
  const [categoryTitle, setCategoryTitle] = useState(infomation?.categoryTitle ? infomation.categoryTitle : "");
  const [categoryId, setcategoryId] = useState(infomation?.categoryId ? infomation.categoryId : 0);
  const [title, setTitle] = useState(infomation?.title ? infomation.title : "");
  const [noteFront, setNoteFront] = useState(infomation?.noteFront ? infomation.noteFront : "");
  const [noteBack, setNoteBack] = useState(infomation?.noteBack ? infomation.noteBack : "");
  const [image, setImage] = useState(infomation?.image ? infomation.image : "");
  const [buttonName, setButtonName] = useState(infomation?.buttonName ? infomation.buttonName : "");
  const [serviceCoin, setServiceCoin] = useState(infomation?.serviceCoin ? infomation.serviceCoin : 0);
  const [coinName, setCoinName] = useState(infomation?.coinName ? infomation.coinName : "");
  const [created_at, setCreated_at] = useState(infomation?.created_at ? infomation.created_at : "");
  const [informationCategories, setInformationCategories] = useState([]);

  const onChange = ({ target }) => {
    switch (target.name) {
      case "categoryTitle":
        setCategoryTitle(target.value);
        break;
      case "categoryId":
        setcategoryId(target.value);
        break;
      case "title":
        setTitle(target.value);
        break;
      case "noteFront":
        setNoteFront(target.value);
        break;
      case "noteBack":
        setNoteBack(target.value);
        break;
      case "image":
        setImage(target.value);
        break;
      case "buttonName":
        setButtonName(target.value);
        break;
      case "serviceCoin":
        setServiceCoin(target.value);
        break;
      case "coinName":
        setCoinName(target.value);
        break;
    }
  };

  useEffect(() => {
    setId(infomation?.id ? infomation.id : "");
    setCategoryTitle(infomation?.categoryTitle ? infomation.categoryTitle : "");
    setcategoryId(infomation?.categoryId ? infomation.categoryId : 0);
    setTitle(infomation?.title ? infomation.title : "");
    setNoteFront(infomation?.noteFront ? infomation.noteFront : "");
    setNoteBack(infomation?.noteBack ? infomation.noteBack : "");
    setImage(infomation?.image ? infomation.image : "");
    setButtonName(infomation?.buttonName ? infomation.buttonName : "閉じる");
    setServiceCoin(infomation?.serviceCoin ? infomation.serviceCoin : "");
    setCoinName(infomation?.coinName ? infomation.coinName : 0);
  }, [infomation]);

  useEffect(() => {
    (async () => {
      const mst = await getMultiSelect("newsCategory");
      setInformationCategories(mst);
    })();
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const data = {
      // categoryTitle: categoryTitle,
      categoryId: categoryId,
      title: title,
      noteFront: noteFront,
      noteBack: noteBack,
      image: image,
      buttonName: buttonName,
      serviceCoin: serviceCoin,
      coinName: coinName,
      created_at: created_at === "" ? dayjs().format("YYYY/MM/DD HH:mm:ss") : created_at,
    };

    // console.log("handleOnSubmitKyc", data);
    let ret;
    if (type == "edit") {
      ret = await updateInfomation(infomation.id, data);
    } else {
      ret = await addInfomation(data);
    }

    if (ret) {
      infomation.categoryTitle = categoryTitle.toString(100);
      infomation.categoryId = categoryId.toString(100);
      infomation.title = title.toString(256);
      infomation.noteFront = noteFront.toString(256);
      infomation.noteBack = noteBack.toString(20);
      infomation.image = image.toString(128);
      infomation.buttonName = buttonName.toString(20);
      infomation.serviceCoin = Number(serviceCoin);
      infomation.coinName = coinName.toString();
      updateInfomations(infomation);
      toast.success("更新しました");
    } else {
      toast.error("更新に失敗しました");
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="mb-4 text-right">
          <button
            onClick={handleClose}
            className="w-6 h-6 inline-flex items-center justify-center bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white border border-gray-500 hover:border-transparent rounde"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} sx={grid}>
          <Grid item xs={5} sx={gridCell}>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 h-full">
              <form className="h-full flex flex-col justify-between" onSubmit={(e) => handleOnSubmit(e)}>
                <div className="flex flex-col space-y-4 flex-1 overflow-auto pr-4">
                  <div className="flex space-x-4 items-center">
                    <InputLabel>カテゴリ</InputLabel>
                    <Select className="w-auto" name="categoryId" value={categoryId} placeholder="選択してください" onChange={onChange}>
                      <MenuItem value={0}>選択してください</MenuItem>
                      {informationCategories?.map((cat, index) => (
                        <MenuItem key={cat.categoryId} value={cat.categoryId}>
                          {cat.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>タイトル</InputLabel>
                    <Input type="text" name="title" value={title} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-start">
                    <div className="h-full flex flex-col">
                      <InputLabel>内容画像</InputLabel>
                      <div className="h-full flex flex-col space-y-4 items-center justify-center">
                        <label className="bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 px-4 rounded">
                          <input type="file" name="imageFile" id="imageFileSelect" className="hidden" />
                          ファイルを選択
                        </label>
                        <button type="button" className="bg-red-500 hover:bg-red-700 text-white text-xs py-2 px-4 rounded">
                          削除
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 space-y-4">
                      {/* <Textarea type="text" name="noteFront" value={noteFront} className="w-full flex-1" onChange={onChange} /> */}
                      <div className="w-full aspect-[4_/_5] object-cover" id="">
                        {image ? <img src={image} className="w-full aspect-[4_/_6] object-cover" /> : <div className="w-full h-full flex items-center justify-center bg-gray-400 text-lg">noimage</div>}
                      </div>
                      {/* <Textarea type="text" name="noteBack" value={noteBack} className="w-full flex-1" onChange={onChange} /> */}
                    </div>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>ボタンタイトル</InputLabel>
                    <Input type="text" name="buttonName" value={buttonName} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>プレゼントコイン</InputLabel>
                    <Input type="text" name="serviceCoin" value={serviceCoin} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>コイン履歴タイトル</InputLabel>
                    <Input type="number" name="coinName" value={coinName} className="flex-1" onChange={onChange} />
                  </div>
                  {id != "" && (
                    <div className="flex space-x-4 items-center">
                      <InputLabel>登録日時</InputLabel>
                      {/* <Input type="text" name="created_at" value={created_at} className="flex-1" onChange={onChange} /> */}
                      {created_at}
                    </div>
                  )}
                </div>
                <div className="text-right bg-white w-full pt-4">
                  {type == "edit" ? (
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      適用
                    </button>
                  ) : (
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      追加
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={7} sx={gridCell}>
            {/* <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div> */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InfomationModal;
