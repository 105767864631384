import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAgencyFromKeyword } from "../data/agency";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { getSystem, updateAppVersion, updateSystem } from "../data/system";
import { toast } from "react-toastify";

const AppSetting = () => {
  const [agencies, setAgencies] = useState([]);
  const [appVersion, setAppVersion] = useState("");
  const [shareTextFront, setShareTextFront] = useState("");
  const [shareTextBack, setShareTextBack] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleGetSystem = async () => {
    const system = await getSystem();
    const av = system.filter((g) => g.type === "app_version");
    if (av.length > 0) {
      console.log(av[0].value);
      setAppVersion(av[0].value);
      setValue("appVersion", av[0].value);
    }

    const stf = system.filter((g) => g.type === "shareTextFront");
    if (stf.length > 0) {
      console.log(stf[0].value);
      setShareTextFront(stf[0].value);
      setValue("shareTextFront", stf[0].value);
    }

    const stb = system.filter((g) => g.type === "shareTextBack");
    if (stb.length > 0) {
      console.log(stb[0].value);
      setShareTextBack(stb[0].value);
      setValue("shareTextBack", stb[0].value);
    }
  };

  const onAppVersionSubmit = async (formData) => {
    let get = "";
    if (formData.appVersion) {
      get = formData.appVersion;
    }

    const ret = await updateSystem(get, "app_version");

    if (ret === "success") {
      toast.success("アプリバージョンを更新しました");
    } else {
      toast.error("アプリバージョンの更新に失敗しました");
    }
    console.log("onSubmit", ret);
  };

  const onShareSubmit = async (formData) => {
    let get = "";
    if (formData.shareTextFront) {
      get = formData.shareTextFront;
    }

    const ret01 = await updateSystem(get, "shareTextFront");

    if (ret01 === "success") {
      toast.success("文章を更新しました");
    } else {
      toast.error("文章の更新に失敗しました");
    }
  };

  useEffect(() => {
    handleGetSystem();
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 w-full">
        <Box sx={{ width: "100%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <Paper className="h-full p-4">
                <h2>アプリバージョン</h2>
                <div>
                  <form onSubmit={handleSubmit(onAppVersionSubmit)} class="mt-6 flex items-center gap-3">
                    <div className="mb-3 flex flex-col items-center">
                      <input type="text" {...register("appVersion", { required: true })} class="w-full p-3 border rounded" placeholder="アプリバージョン" />
                      <p className="text-red-700">{errors.appVersion?.type === "required" && "アプリバージョンを入力してください。"}</p>
                    </div>
                    <div className="">
                      <input type="submit" value="更新" class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
                    </div>
                  </form>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className="h-full p-4">
                <h2>SNSシェア設定</h2>
                <div>
                  <form onSubmit={handleSubmit(onShareSubmit)} class="mt-6 flex flex-col items-center gap-3">
                    <div className="mb-3 flex flex-col items-center">
                      <textarea cols="30" rows="6" placeholder="前半文章" {...register("shareTextFront", { required: true })} className="w-full p-3 border rounded"></textarea>
                      <p className="text-red-700">{errors.shareTextFront?.type === "required" && "前半文章を入力してください。"}</p>
                    </div>
                    <div>
                      文章中の特殊文字は以下に置換されます。
                      <br />
                      「%TITLE%」= シェアする投稿のタイトル
                      <br />
                      「%URL%」= https://mimikomi.net/share?p=投稿ID
                    </div>
                    <div className="w-full text-right">
                      <input type="submit" value="更新" class="mb-3 bg-blue-800 text-white py-2 px-4 rounded hover:opacity-75" />
                    </div>
                  </form>
                </div>
              </Paper>
            </Grid>
            {/* <Grid item xs={4}>
              <Paper>1</Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>1</Paper>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default AppSetting;
