import React, { useEffect, useState } from "react";
import { getStatistics } from "../data/statistics";
import { Box, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1400,
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const grid = {
  width: "100%",
  height: "100%",
};

const gridCell = {
  height: "30%",
};

const Home = () => {
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    const init = async () => {
      const data = await getStatistics();
      setStatistics(data);
    };

    init();
  }, []);
  return (
    <div className="w-full">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} sx={grid}>
        <Grid item xs={6} sx={gridCell}>
          <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full">
            <div className="text-2xl">
              <span>現在のユーザー数：</span>
              <span>{statistics.userCount} 人</span>
            </div>
            <div>
              <span>先月までのユーザー数：</span>
              <span>{statistics.prevUserCount} 人</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sx={gridCell}>
          <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full">
            <div className="text-2xl">
              <span>現在の投稿数：</span>
              <span>{statistics.postCount} 件</span>
            </div>
            <div>
              <span>有料投稿数：</span>
              <span>{statistics.paidPostCount} 件</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sx={gridCell}>
          <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full"></div>
        </Grid>
        <Grid item xs={6} sx={gridCell}>
          <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1 h-full"></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
