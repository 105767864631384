import axios from "axios";

const Instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true
});

export default Instance;