import Instance from "../util/AxiosInstance";

export const getInfomationFromKeyword = async (keyword) => {
    return await Instance.get("/infomation/")
        .then((response) => {
            if (response.data !== "failure") {
                console.log('getInfomationFromKeyword', response);
                return response.data;
            } else {
                return [];
            }
        });
}

export const updateInfomation = async (id, formData) => {

    const json = JSON.stringify(formData);
    console.log('updateInfomation', json);

    return await Instance.put("/infomation/?id=" + id, json)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('updateInfomation', response);
                return response.data;
            } else {
                return [];
            }
        });
}

export const addInfomation = async (formData) => {

    const json = JSON.stringify(formData);
    console.log('addInfomation', json);

    return await Instance.post("/infomation/", json)
        .then((response) => {
            if (response.data !== "failure") {
                console.log('addInfomation', response);
                return response.data;
            } else {
                return [];
            }
        });
}