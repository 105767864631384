import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../componets/shared/Sidebar";
import Header from "../componets/shared/Header";

const Layout = () => {
  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden">
      <Sidebar />
      <div className="w-full p-4 overflow-auto">
        {/* <Header /> */}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
