import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid, Input, InputLabel, TextareaAutosize } from "@mui/material";
import UserDetailTable from "./Table/UserDetailTable";
import { addAgency, updateAgency } from "../data/agency";
import { toast } from "react-toastify";
import { Textarea } from "@material-tailwind/react";
import { type } from "@testing-library/user-event/dist/type";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1400,
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  pb: 8,
};

const grid = {
  height: "100%",
};

const gridCell = {
  height: "100%",
};

const AgencyModal = (props) => {
  const { open, handleClose, modalMode, agency, updateAgencies, addAgencies } = props;

  const [agencyName, setAgencyName] = useState(agency?.agencyName ? agency.agencyName : "");
  const [loginId, setloginId] = useState(agency?.loginId ? agency.loginId : "");
  const [password, setPassword] = useState(agency?.password ? agency.password : "");
  const [address, setAddress] = useState(agency?.address ? agency.address : "");
  const [tel, setTel] = useState(agency?.tel ? agency.tel : "");
  const [contactName, setContactName] = useState(agency?.contactName ? agency.contactName : "");
  const [contactTel, setContactTel] = useState(agency?.contactTel ? agency.contactTel : "");
  const [contactMail, setContactMail] = useState(agency?.contactMail ? agency.contactMail : "");
  const [note, setNote] = useState(agency?.note ? agency.note : "");

  const onChange = ({ target }) => {
    switch (target.name) {
      case "agencyName":
        setAgencyName(target.value);
        break;
      case "loginId":
        setloginId(target.value);
        break;
      case "password":
        setPassword(target.value);
        break;
      case "address":
        setAddress(target.value);
        break;
      case "tel":
        setTel(target.value);
        break;
      case "contactName":
        setContactName(target.value);
        break;
      case "contactTel":
        setContactTel(target.value);
        break;
      case "contactMail":
        setContactMail(target.value);
        break;
      case "note":
        setNote(target.value);
        break;
    }
  };

  useEffect(() => {
    setAgencyName(agency?.agencyName ? agency.agencyName : "");
    setloginId(agency?.loginId ? agency.loginId : "");
    setPassword(agency?.password ? agency.password : "");
    setAddress(agency?.address ? agency.address : "");
    setTel(agency?.tel ? agency.tel : "");
    setContactName(agency?.contactName ? agency.contactName : "");
    setContactTel(agency?.contactTel ? agency.contactTel : "");
    setContactMail(agency?.contactMail ? agency.contactMail : "");
    setNote(agency?.note ? agency.note : "");
  }, [agency]);

  const columnsCoins = [
    {
      width: 120,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "価格",
      dataKey: "jpy",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsBuyPosts = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsBuyItems = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsSalesPosts = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 60,
      label: "レート",
      dataKey: "rate",
      numeric: true,
    },
    {
      width: 60,
      label: "ポイント",
      dataKey: "point",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const columnsSalesItems = [
    {
      width: 120,
      label: "タイトル",
      dataKey: "title",
    },
    {
      width: 60,
      label: "コイン",
      dataKey: "coin",
      numeric: true,
    },
    {
      width: 60,
      label: "レート",
      dataKey: "rate",
      numeric: true,
    },
    {
      width: 60,
      label: "ポイント",
      dataKey: "point",
      numeric: true,
    },
    {
      width: 120,
      label: "購入日",
      dataKey: "created_at",
      date: true,
    },
  ];

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const data = {
      agencyName: agencyName,
      loginId: loginId,
      password: password,
      address: address,
      tel: tel,
      contactName: contactName,
      contactTel: contactTel,
      contactMail: contactMail,
      note: note,
    };

    // console.log("handleOnSubmitKyc", data);
    let ret;
    if (type == "edit") {
      ret = await updateAgency(agency.id, data);
    } else {
      ret = await addAgency(data);
    }

    if (ret) {
      agency.agencyName = agencyName.toString(100);
      agency.loginId = loginId.toString(100);
      agency.password = password.toString(256);
      agency.address = address.toString(256);
      agency.tel = tel.toString(20);
      agency.contactName = contactName.toString(128);
      agency.contactTel = contactTel.toString(20);
      agency.contactMail = contactMail.toString(128);
      agency.note = note.toString();
      updateAgencies(agency);
      toast.success("更新しました");
    } else {
      toast.error("更新に失敗しました");
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="mb-4 text-right">
          <button
            onClick={handleClose}
            className="w-6 h-6 inline-flex items-center justify-center bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white border border-gray-500 hover:border-transparent rounde"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }} sx={grid}>
          <Grid item xs={4} sx={gridCell}>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 h-full">
              <form className="h-full flex flex-col justify-between" onSubmit={(e) => handleOnSubmit(e)}>
                <div className="flex flex-col space-y-4 flex-1 overflow-auto">
                  <div className="flex space-x-4 items-center">
                    <InputLabel>会社名</InputLabel>
                    <Input type="text" name="agencyName" value={agencyName} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>住所</InputLabel>
                    <Input type="text" name="address" value={address} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>電話番号</InputLabel>
                    <Input type="text" name="tel" value={tel} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>担当者名</InputLabel>
                    <Input type="text" name="contactName" value={contactName} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>担当者電話番号</InputLabel>
                    <Input type="text" name="contactTel" value={contactTel} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>担当者メール</InputLabel>
                    <Input type="email" name="contactMail" value={contactMail} className="flex-1" onChange={onChange} />
                  </div>
                  <div>
                    <Textarea type="text" label="備考" name="note" value={note} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>ログインID</InputLabel>
                    <Input type="text" name="loginId" value={loginId} className="flex-1" onChange={onChange} />
                  </div>
                  <div className="flex space-x-4 items-center">
                    <InputLabel>パスワード</InputLabel>
                    <Input type="password" name="password" value={password} className="flex-1" onChange={onChange} />
                  </div>
                </div>
                <div className="text-right bg-white w-full pt-4">
                  {type == "edit" ? (
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      適用
                    </button>
                  ) : (
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      追加
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={8} sx={gridCell}>
            {/* <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div>
            <div className="bg-white px-4 pt-3 pb-4 roundet-sm border border-r-gray-200 flex-1"></div> */}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AgencyModal;
