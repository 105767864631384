import React, { useContext, useState } from "react";
import Instance from "../util/AxiosInstance";

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const value = {
        isAuth,
        setIsAuth
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;